import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CarouselQuote from '../components/carouselQuote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const ServicesTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}  
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={post.frontmatter.headline.length >= 1 ? '8' : '12'}>
                <h3 className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </h3>
                <div className="richtext divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>

              {(post.frontmatter.headline != null && post.frontmatter.headline.length > 0) && (
                <MDBCol lg="4" md="12" className="pl-lg-5">
                  {post.frontmatter.headline.map((headlines, index) => (
                    <div className="headline-tile mt-lg-3 item-headline" key={index} >
                      <div className="headline-icon"> 
                        <FontAwesomeIcon icon={['fat', headlines.icon]} size="2x" />
                      </div>
                      <div className="headline-content">
                        <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-medium text-medium"> {headlines.title} </h3>
                        <p className="font-alt letter-spacing-1 text-xs-medium text-small"> {headlines.description} </p>
                        <div className="headline-action font-alt letter-spacing-1 text-xs-medium text-small">
                          <Link to={headlines.link} className="effect-noline">
                            {headlines.linktext}
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.quote && post.frontmatter.quote.length > 0 && (
          <section className="blockback">
            {post.frontmatter.quote.map((quotes, index) => {
              return (
                <CarouselQuote key={index} quotenumber={quotes.quotenumber} strapline={quotes.strapline} author={quotes.author} />
              )
            })}
          </section>
        )}
      </Layout>
    </div>
  )
}
export default ServicesTemplate

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {   
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "services" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        ctatext
        ctaslug          
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        headline {
          title
          icon
          description
          linktext
          link
        }
        quote {
          quotenumber
          strapline
          author
        }
        related {
          title
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
          description
          linktext
          link
        }
      }
    }
  }
`